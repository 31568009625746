<template>
    <mercur-card class="full-height-layout fill">
        <grid-header :quickSearch.sync="filters.search">
            Carriers
            <template slot="actions">
                <mercur-button @click="openAddCarrierDialog" v-if="isAllowedTo('createCarrier')" class="btn btn-raised btn-yellow text-uppercase">
                    <i class="fas fa-plus"></i>
                    Add new carrier
                </mercur-button>
                <mercur-button @click="triggerConfirmSyncCarriers" v-if="isAllowedTo('createCarrier')" class="btn btn-raised text-uppercase">
                    <i class="fas fa-download"></i>
                    Hello Octo Sync
                </mercur-button>
            </template>
        </grid-header>
        <data-table
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            :quickSearch="filters.search"
            v-if="isAllowedTo('getCarriers')"
        />

        <p class="permission-message" v-else>Not allowed to see this view</p>

        <mercur-dialog :is-open.sync="addCarrierDialogShow" width="80%">
            <div slot="header"><h3>Add carrier</h3></div>
            <div slot="default">
                <form @submit.prevent="submitAddCarrier">
                    <mercur-tabs :active-tab.sync="activeTab">
                        <mercur-tab title="General" id="general">
                            <p>Please enter the name for the new carrier</p>
                            <mercur-input
                                v-model.trim="$v.addCarrierDialogParams.carrierName.$model"
                                :disabled="addCarrierDialogLoading"
                                required
                                ref="addCarrierNameInputfield"
                                :class="{'form-invalid': $v.addCarrierDialogParams.carrierName.$error}">
                                Carrier name
                                <template slot="note">
                                    <span class="form-error" v-if="!$v.addCarrierDialogParams.carrierName.required">Carrier name is required</span>
                                </template>
                            </mercur-input>
                        </mercur-tab>
                        <mercur-tab title="Delivery days" id="deliveryDays">
                            <working-days-fields v-model="$v.addCarrierDialogParams.deliveryDays.$model" :enable-cutoff-time="true" :show-title="false"></working-days-fields>
                        </mercur-tab>
                    </mercur-tabs>
                </form>
            </div>
            <div slot="footer">
                <mercur-button class="btn btn-raised text-uppercase" @click="addCarrierDialogShow = false">Close</mercur-button>
                <mercur-button class="btn btn-raised text-uppercase" :disabled="addCarrierDialogLoading || $v.addCarrierDialogParams.$invalid" @click="nextStep">
                    <span v-if="activeTab === 'general'">Continue</span>
                    <span v-else>Save</span>
                </mercur-button>
            </div>
            <mercur-progress-bar indeterminate v-if="addCarrierDialogLoading" />
        </mercur-dialog>
        <mercur-dialog data-test="carrierSyncConfirmDialog" :is-open.sync="isCarrierSyncConfirmDialogOpen">
            <p>Are you sure you want to sync all carrier data?</p>
            <template slot="footer">
                <div class="full-width">
                    <p>Presta carrier mapping</p>
                    <simple-filedropper @onFile="onFile" allowedExtensions=".csv" />
                </div>
                <mercur-button class="btn btn-raised" :disabled="syncCarrierLoading" @click="triggerCancelSyncCarriers">Cancel</mercur-button>
                <mercur-button data-test="serviceLevelMappingDeleteModalConfirmButton" class="btn btn-raised btn-primary" :disabled="prestaCarrierFile === null || syncCarrierLoading" @click="syncHelloOcto">Start Sync</mercur-button>
            </template>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'

import ApiServerSideDataSource from '@/components/utils/ApiServerSideDataSource'
import { required } from 'vuelidate/lib/validators'
import WorkingDaysFields from '@/components/WorkingDaysFields'
import GridHeader from '@/components/GridHeader'
import DataTable from '@/components/DataTable'
import SimpleFiledropper from '@/components/utils/SimpleFiledropper'

export default {
    name: 'CarriersOverview',
    components: { DataTable, GridHeader, WorkingDaysFields, SimpleFiledropper },
    data () {
        return {
            prestaCarrierFile: null,
            url: CONFIG.API.ROUTES.CARRIERS.OVERVIEW,
            options: {
                columns: {
                    'Name': {
                        field: 'carrierName',
                        sortable: true,
                        link: (value, data) => {
                            return {
                                name: 'CarrierView',
                                params: {
                                    carrierId: data.carrierId,
                                },
                            }
                        },
                    },
                    'Created': {
                        field: 'dateCreated',
                        sortable: true,
                    },
                    'Updated': {
                        field: 'dateUpdated',
                        sortable: true,
                    },
                },
                quickSearchColumns: ['carrierName'],
            },
            filters: {
                search: '',
            },
            addCarrierDialogShow: false,
            addCarrierDialogLoading: false,
            addCarrierDialogParams: {},
            activeTab: 'general',
            isCarrierSyncConfirmDialogOpen: false,
            syncCarrierLoading: false,
        }
    },

    validations: {
        addCarrierDialogParams: {
            carrierName: {
                required,
            },
            deliveryDays: {

            },
        },
    },

    beforeMount () {
        this.gridOptions = {
        }

        this.columnDefs = [
            {
                headerName: 'Name',
                field: 'carrierName',
                colId: 'carrierName',
                cellRendererFramework: 'RouterlinkCell',
                cellRendererParams: (params) => {
                    return {
                        routerlink: {
                            to: {
                                name: 'CarrierView',
                                params: {
                                    carrierId: params.data.carrierId,
                                    ...params.data,
                                },
                            },
                        },
                    }
                },
            },
            {
                headerName: 'Created',
                field: 'dateCreated',
                sortable: false,
                valueFormatter: ({ value }) => {
                    return this.$options.filters.asDatetime(value)
                },
            },
            {
                headerName: 'Updated',
                field: 'dateUpdated',
                sortable: false,
                valueFormatter: ({ value }) => {
                    return this.$options.filters.asDatetime(value)
                },
            },
            {
                headerName: '',
                width: 100,
                hide: false,
                cellRendererFramework: 'ActionsCell',
                cellRendererParams: (params) => {
                    return {
                        actions: [
                            {
                                text: 'Edit',
                                icon: 'fas fa-edit',
                                to: (params) => {
                                    return {
                                        name: 'CarrierEdit',
                                        params: {
                                            carrierId: params.data.carrierId,
                                            ...params.data,
                                        },
                                    }
                                },
                            },
                        ],
                    }
                },
            },
        ]

        this.defaultColDef = {
            editable: false,
            enableRowGroup: false,
            enablePivot: false,
            enableValue: true,
            sortable: true,
            resizable: true,
            filter: 'agTextColumnFilter',
        }
    },
    mounted () {
        this.gridApi = this.gridOptions.api
        this.gridColumnApi = this.gridOptions.columnApi
    },
    methods: {
        onFile (fileContent) {
            this.prestaCarrierFile = fileContent
        },
        onGridReady (params) {
            this.gridApi.setSortModel([{
                colId: 'carrierName',
                sort: 'asc',
            }])

            this.serverSideDatasource = new ApiServerSideDataSource({
                $api: this.$api,
                url: this.url,
                getFilterModel: () => this.filterModel,
                responseHandler: (successCallback, { data }) => {
                    if (data.items.length === 0) {
                        params.api.showNoRowsOverlay()
                    }
                    successCallback(data.items, data.items[0].totalRows)
                },
            })

            params.api.setServerSideDatasource(this.serverSideDatasource)

            setTimeout(() => {
                params.api.sizeColumnsToFit()
            }, 100)
            window.addEventListener('resize', () => {
                this.gridApi.sizeColumnsToFit()
            })
        },
        onModelUpdated (params) {
            params.api.sizeColumnsToFit()
        },

        openAddCarrierDialog (params) {
            this.$v.$reset()
            this.activeTab = 'general'
            this.addCarrierDialogParams = {
                ...params,
                carrierName: '',
                deliveryDays: [
                    {
                        'dayOfWeek': 1,
                        'cutOffTime': '14:00',
                    },
                    {
                        'dayOfWeek': 2,
                        'cutOffTime': '14:00',
                    },
                    {
                        'dayOfWeek': 3,
                        'cutOffTime': '14:00',
                    },
                    {
                        'dayOfWeek': 4,
                        'cutOffTime': '14:00',
                    },
                    {
                        'dayOfWeek': 5,
                        'cutOffTime': '14:00',
                    },
                ],
            }
            this.addCarrierDialogShow = true
        },
        triggerConfirmSyncCarriers () {
            this.isCarrierSyncConfirmDialogOpen = true
        },
        triggerCancelSyncCarriers () {
            this.prestaCarrierFile = null
            this.isCarrierSyncConfirmDialogOpen = false
        },
        syncHelloOcto () {
            const syncCarrierUrl = CONFIG.API.ROUTES.CARRIERS.TASKS.SYNC

            this.syncCarrierLoading = true
            this.addJob(syncCarrierUrl)
            this.$api.post(syncCarrierUrl, { file: this.prestaCarrierFile }).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `Carrier sync process started.`,
                    type: 'success',
                    errors: data,
                })
            }).catch(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: data.message || `Carrier sync process failed to start. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(syncCarrierUrl)
                this.syncCarrierLoading = false
                this.isCarrierSyncConfirmDialogOpen = false
            })
        },

        nextStep () {
            if (this.activeTab === 'general') {
                this.activeTab = 'deliveryDays'
                return
            }

            this.submitAddCarrier()
        },

        submitAddCarrier () {
            if (!this.isAllowedTo('createCarrier')) {
                return
            }
            const params = this.addCarrierDialogParams
            const url = CONFIG.API.ROUTES.CARRIERS.ADD

            this.addCarrierDialogLoading = true
            this.addJob(url)
            this.$api.post(url, params).then(({ data }) => {
                this.addCarrierDialogShow = false
                this.$root.$emit('notification:global', {
                    message: `Carrier was added`,
                })
                this.gridApi.purgeServerSideCache()
            }).catch(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `Adding carrier failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.addCarrierDialogLoading = false
                this.finishJob(url)
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.full-width {
    width: 100%
}
</style>
