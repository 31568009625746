<template>
    <div class="workingdays">
        <div class="p-4 bg-primary" v-if="showTitle">
            <h3 class="m-0 p-0 text-white"><slot>Working days</slot></h3>
        </div>

        <div class="p-4" v-if="enableCutoffTime">
            <mercur-checkbox data-test="standardCutOffTimeCheckbox" v-model="hasStandardCutOffTime">Standard cut-off time is the same for every working day</mercur-checkbox>
            <div class="flex mt-4 align-items-center" v-if="hasStandardCutOffTime">
                <mercur-input
                    data-test="standardCutOffTimeInput"
                    v-model="standardCutOffTime"
                    :required="hasStandardCutOffTime"
                    type="time"
                    class="w-100">
                    Cutoff time
                </mercur-input>
                <i class="fas fa-bell-slash ml-3"></i>
            </div>
        </div>

        <div class="p-4" v-if="enableWorkingHours">
            <mercur-checkbox data-test="standardStandardHoursCheckbox" v-model="hasStandardWorkingHours">Standard working hours are the same for every working day</mercur-checkbox>
            <div class="flex mt-4 align-items-center" v-if="hasStandardWorkingHours">
                <mercur-input
                    data-test="standardStandardOpenTimeInput"
                    v-model="standardOpenTime"
                    :required="hasStandardWorkingHours"
                    type="time"
                    :max="addMinutes(standardCloseTime, -1)"
                    class="w-100">
                    Open time
                </mercur-input>
                <i class="fas fa-clock ml-3"></i>
            </div>

            <div class="flex mt-2 align-items-center" v-if="hasStandardWorkingHours">
                <mercur-input
                    data-test="standardStandardCloseTimeInput"
                    v-model="standardCloseTime"
                    :required="hasStandardWorkingHours"
                    type="time"
                    :min="addMinutes(standardOpenTime, 1)"
                    class="w-100">
                    Close time
                </mercur-input>
                <i class="fas fa-clock ml-3"></i>
            </div>
        </div>
        <hr class="border-top" v-if="enableWorkingHours || enableCutoffTime" />

        <h3 class="px-3 pt-2 font-weight-normal">Select working days</h3>

        <div class="">
            <div v-for="(workingDay, key) in workingDays" :key="key">
                <div :class="{'border-top': key !== 0}" class="py-2 px-4">
                    <mercur-checkbox :data-test="'standardWorkingDaysCheckbox'+workingDay.dayOfWeekName" v-model="workingDay.active">{{workingDay.dayOfWeekName}}</mercur-checkbox>
                </div>
                <div class="flex mt-2 align-items-center px-4" v-if="enableCutoffTime && workingDay.active && !hasStandardCutOffTime">
                    <mercur-input
                        v-model="workingDays[key].cutOffTime"
                        :data-test="'standardWorkingDaysInput'+workingDay.dayOfWeekName"
                        required
                        type="time"
                        class="w-100">
                        Cutoff time
                    </mercur-input>
                    <i class="fas fa-bell-slash ml-3"></i>
                </div>

                <div class="px-4" v-if="enableWorkingHours && workingDay.active && !hasStandardWorkingHours">
                    <div class="flex mt-4 align-items-center" v-if="hasStandardWorkingHours">
                        <mercur-input
                            v-model="workingDay.openTime"
                            required
                            type="time"
                            :max="addMinutes(standardCloseTime, -1)"
                            class="w-100">
                            Open time
                        </mercur-input>
                        <i class="fas fa-clock ml-3"></i>
                    </div>

                    <div class="flex mt-2 align-items-center" v-if="hasStandardWorkingHours">
                        <mercur-input
                            v-model="workingDay.closeTime"
                            required
                            type="time"
                            :min="addMinutes(standardOpenTime, 1)"
                            class="w-100">
                            Close time
                        </mercur-input>
                        <i class="fas fa-clock ml-3"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WorkingDaysFields',
    props: {
        value: {
            default: () => {
                return []
            },
        },
        enableCutoffTime: {
            default: false,
        },
        enableWorkingHours: {
            default: false,
        },
        showTitle: {
            default: true,
        },
    },
    data () {
        return {
            workingDays: [],
            hasStandardCutOffTime: false,
            hasStandardWorkingHours: false,
            standardCutOffTime: null,
            standardOpenTime: null,
            standardCloseTime: null,
        }
    },

    watch: {
        standardCutOffTime (value) {
            this.workingDays.forEach((item) => {
                this.$set(item, 'cutOffTime', value)
            })
        },
        standardOpenTime (value) {
            this.workingDays.forEach((item) => {
                this.$set(item, 'openTime', value)
            })
        },
        standardCloseTime (value) {
            this.workingDays.forEach((item) => {
                this.$set(item, 'closeTime', value)
            })
        },
        workingDays: {
            deep: true,
            handler () {
                this.$emit('input', this.workingDays)
            },
        },
    },

    computed: {
        activeWorkingDays () {
            return this.workingDays.filter((day) => day.active)
        },
    },

    methods: {
        calculateInitialStandardTimes () {
            const hasStandardCutOffTime = this.activeWorkingDays.every((day, i, arr) => day.cutOffTime === arr[0].cutOffTime)
            this.$set(this, 'hasStandardCutOffTime', hasStandardCutOffTime)

            if (hasStandardCutOffTime && this.activeWorkingDays.length) {
                this.$set(this, 'standardCutOffTime', this.workingDays.find((day) => day.active).cutOffTime)
            }

            const hasStandardWorkingHours = this.activeWorkingDays.every((day, i, arr) => {
                return day.openTime === arr[0].openTime && day.closeTime === arr[0].closeTime
            })
            this.$set(this, 'hasStandardWorkingHours', hasStandardWorkingHours)

            if (hasStandardWorkingHours && this.activeWorkingDays.length) {
                this.$set(this, 'standardOpenTime', this.workingDays.find((day) => day.active).openTime)
                this.$set(this, 'standardCloseTime', this.workingDays.find((day) => day.active).closeTime)
            }
        },

        addMinutes (timeString, nrMinutes) {
            if (!timeString) {
                return false
            }

            const splitted = timeString.split(':')
            const newTimeString = new Date(1970, 0, 1, splitted[0], splitted[1])
            newTimeString.setMinutes(parseInt(splitted[1]) + nrMinutes)
            const hours = ('0' + newTimeString.getHours()).slice(-2)
            const minutes = ('0' + newTimeString.getMinutes()).slice(-2)

            return `${hours}:${minutes}`
        },
        getDefaultWorkingDays () {
            return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => {
                return {
                    dayOfWeekName: day,
                    dayOfWeek: index,
                    cutOffTime: '',
                    openTime: '',
                    closeTime: '',
                    active: false,
                }
            })
        },
    },

    created () {
        this.workingDays = this.getDefaultWorkingDays().map((def) => {
            return {
                ...def,
                ...this.value.find((a) => def.dayOfWeek === a.dayOfWeek),
            }
        }).map(workingDay => {
            if (!this.enableCutoffTime) {
                delete workingDay.cutOffTime
            }

            if (!this.enableWorkingHours) {
                delete workingDay.closeTime
                delete workingDay.openTime
            }
            return workingDay
        })
        this.calculateInitialStandardTimes()
    },
}
</script>

<style lang="scss" scoped>
    .workingdays {
        width: 95vw;
        max-width: 100%;
        display: inline-block;
        vertical-align: top;
        overflow: auto;
        border: 1px solid rgba(#000, .12);
        margin-bottom: 20px;
    }
</style>
