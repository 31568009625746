<template>
    <div>
        <div
            class="filedropper"
            :class="{'filedropper--is-highlighted': highlighted}"
            @dragenter.prevent.stop="highlight"
            @dragover.prevent.stop="highlight"
            @dragleave.prevent.stop="unhighlight"
            @drop="dropped"
            @click="$refs.fileinput.click()"
        >
            <input
                @change="selected"
                type="file"
                name="file"
                ref="fileinput"
                class="filedropper__input"
                :accept="this.allowedExtensions || '*'"
            />
            Drop a file or <mercur-button class="btn btn-raised text-uppercase ml-2">{{buttonText}}</mercur-button>
        </div>
        <p v-if="fileName">Selected file: <b>{{fileName}}</b></p>
    </div>
</template>

<script>
export default {
    name: 'SimpleFiledropper',
    props: {
        buttonText: {
            default: 'Select',
        },
        allowedExtensions: {
            default: null,
        },
    },
    data () {
        return {
            fileContent: null,
            fileName: null,
            highlighted: false,
        }
    },

    methods: {
        highlight () {
            this.highlighted = true
        },

        unhighlight () {
            this.highlighted = false
        },

        selected ($event) {
            this.handleFileSelected($event.target.files)
        },

        dropped ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            this.handleFileSelected($event.dataTransfer.files)
        },
        handleFileSelected (files) {
            if (!files.length) return

            const reader = new FileReader()
            reader.readAsText(files[0])
            this.fileName = files[0].name
            reader.onload = () => { this.fileContent = reader.result; this.$emit('onFile', this.fileContent) }
        },
    },

}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/components/_filedropper.scss';
</style>
